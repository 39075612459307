import PlayIcon from "@svg/PlayIcon";
import { ISkuModel } from "@types";
import Image from "next/image";

interface VideoCardProps {
  sku?: ISkuModel
}

const VideoCard: React.FC<VideoCardProps> = ({
  sku
}) => {

  if (!sku?.video) return <></>
  return (
    <div className="w-[194px] h-[271.5px]  rounded-2xl overflow-hidden cursor-pointer border">
      <div className="w-full h-full relative">
        <Image layout="fill" src={sku.video.VCover} alt={sku.name}
          loading="lazy" sizes="100vw"
        />
        <div className=" w-10 h-10 bg-white rounded-full absolute bottom-3 right-3 flex items-center ">
          <PlayIcon className=" mx-auto" />
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
