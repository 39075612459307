import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const PlayIcon: React.FC<Props> = (props) => {
  return (
    <svg {...props} width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.90767 0.291469L14.5292 7.55514C15.7208 8.29947 15.7208 10.0338 14.5273 10.7763L2.90767 18.04C1.64267 18.832 0 17.9208 0 16.4285V1.90297C0 0.410636 1.64267 -0.498698 2.90767 0.291469Z" fill="#008AF6" />
    </svg>
  );
};

export default PlayIcon;
