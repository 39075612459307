import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  FreeMode,
  Mousewheel,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Link from "next/link";
import { IEntityInput, IHomeItemDetailInput, IHomeItemInput, ISkuModel, IVideoProductInput } from "@types";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useRouter } from "next/router";
import VideoCard from "@elements/videoCard";
import { useEffect, useState } from "react";
import Modal from "@modules/layout/modal";
import Player from "@elements/player";
import VideoProductCard from "@elements/videoProductCard";
import VideoActionCard from "@elements/videoActionCard";
import useEvents from "@hooks/useEvents";
import useVideoPlayer from "@hooks/useVideoPlayer";
import { linkGenerator } from "@utils/linkGenerator";
interface VideoListProps {
  data: IHomeItemInput;
  sourceEntity?: IEntityInput;
}

const VideoList: React.FC<VideoListProps> = ({ data, sourceEntity }) => {
  const { locale } = useRouter();
  const { videoListViewed, skuVideoPlayed } = useEvents()
  const { videList, isFetchingVideo, fetchData, hasMore } = useVideoPlayer()
  const [showVideo, setShowVideo] = useState(false)
  const [selectedSku, setSelectedSku] = useState<ISkuModel | undefined>(undefined)
  const [selectedItem, setSelectedItem] = useState<IHomeItemDetailInput | IVideoProductInput | undefined>(undefined)
  SwiperCore.use([
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    FreeMode,
    Mousewheel,
  ]);
  const source = {
    from_page: "homepage",
    from_entity: "home",
    from_entity_slug: data.entity?.slug || "",
    from_page_section: "",
    from_page_section_entity: "sku_video",
    from_page_section_slug: "",
  }
  const source_feed = {
    from_page: "video_feed",
    from_entity: "sku",
    from_entity_slug: selectedSku?.slug || "",
    from_page_section: "",
    from_page_section_entity: "",
    from_page_section_slug: "",
  }
  const index = videList.findIndex(item => item.default_sku_id === selectedSku?.id)
  const length = videList.length


  useEffect(() => {
    if (selectedSku) {
      setShowVideo(true)
      if ((length - index) <= 3 && hasMore && !isFetchingVideo) {
        fetchData()
      }
    }
    else {
      setShowVideo(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSku])

  const onClose = () => {
    setSelectedSku(undefined)
    setSelectedItem(undefined)
  }


  const onNext = () => {
    if (index + 1 !== length) {
      const target = videList[(index + 1)]
      const sku = target.skus?.find(sku => sku.id === target.default_sku_id)
      setSelectedSku(sku)
      setSelectedItem(target)
      if (sku) {
        skuVideoPlayed(source_feed, sku, "next", { slug: target?.slug || "", id: target?.id || -1 })
      }
    }
  }
  const onBack = () => {
    if (index !== 0) {
      const target = videList[(index - 1)]
      const sku = target.skus?.find(sku => sku.id === target.default_sku_id)
      setSelectedSku(sku)
      setSelectedItem(target)
      if (sku) {
        skuVideoPlayed(source_feed, sku, "previous", { slug: target?.slug || "", id: target?.id || -1 })
      }
    }
  }

  const handleSelectVideo = (item: IHomeItemDetailInput) => {
    const product = videList?.find(product => product.default_sku_id === item.default_sku_id)
    const sku = product?.skus?.find(sku => sku.id === item.default_sku_id)
    setSelectedItem(product)
    setSelectedSku(sku)
    if (sku) {
      skuVideoPlayed(source, sku, "first", { slug: product?.slug || "", id: product?.id || -1 })
    }
  }


  if (!videList.length) return <></>;
  return (
    <div className="w-full max-w-[1240px] m-auto newProduct-container ">
      {showVideo && selectedSku?.video && <Modal open={showVideo} onClose={onClose} cancelable={false} isCenter>
        <div className="md:w-[383px] sm:w-screen xs:w-screen w-screen  max-w-[383px]   h-max max-h-[732px] flex flex-col justify-center items-center relative ">
          <div className="w-full h-max px-2 md:static absolute z-10 inset-0 " >
            <VideoActionCard index={index} onClose={onClose} onNext={onNext} onBack={onBack} isList length={length} hasMore={hasMore} sku={selectedSku} />
          </div>
          <div className="w-full  overflow-hidden" tabIndex={1}>
            <div className=" w-full pt-[140%] relative">
              <div className=" absolute inset-0 w-full h-full">
                <Player autoPlayOnSrcChange subtitle="" sources={[selectedSku.video]} poster={selectedSku.video.VCover} onEnd={onNext} />
              </div>
              <div className="w-full bottom-0 left-0 p-4 absolute  cart_video rounded-b-2xl" >
                <div className="w-full min-h-[88px] h-max bg-white rounded-2xl p-3">
                  <VideoProductCard sku={selectedSku} productSlug={selectedItem?.slug} hasLink productName={selectedItem?.name} productId={selectedItem?.id} category={selectedItem?.category} subcategory={selectedItem?.subcategory} brand={selectedItem?.brand} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </Modal>}
      <div className="w-full flex justify-between items-center space-x-2">
        <h1 className=" flex-1 font-extrabold sm:text-2xl xs:text-lg text-lg text-[#222222] whitespace-nowrap text-ellipsis overflow-hidden ">
          {data.header?.name}
        </h1>
        {data.header?.more ? (
          <Link
            href={linkGenerator(data.header.more.action)}
            passHref
            prefetch={false}
          >
            <a onClick={() => {
              videoListViewed({
                from_page: "homepage",
                from_entity: "home",
                from_entity_slug: sourceEntity?.slug || "",
                from_page_section: "Videos",
                from_page_section_entity: "",
                from_page_section_slug: "",
              })
            }}>
              <div className={`${locale?.includes("ar") ? "pl-[11px] pr-5" : "pl-5 pr-[11px]"} rounded-full border h-10 flex items-center space-x-1 text-sm font-normal text-brandBlue py-2 `}>
                <span >{data.header.more.name}</span>
                <ChevronRightIcon
                  className={`${locale?.includes("ar") ? "rotate-180" : ""} h-6 w-6`}
                  aria-hidden="true"
                />
              </div>
            </a>
          </Link>
        ) : null}
      </div>
      <div className="w-full overflow-auto flex mt-5">
        <Swiper
          noSwiping={true}
          noSwipingSelector="button"
          spaceBetween={10}
          slidesPerView={"auto"}
          freeMode={true}
          mousewheel={true}
          modules={[Navigation, Autoplay, FreeMode, Mousewheel]}
          allowTouchMove={true}
          loop={false}
          className={"swiper-padding w-full"}
          watchSlidesProgress={true}
        >
          {data.items.map((item) => {
            const sku = item.skus?.find(sku => sku.id === item.default_sku_id)
            return (
              <SwiperSlide key={item.default_sku_id} className="w-max">
                <div onClick={() => handleSelectVideo(item)}>
                  <VideoCard sku={sku} />
                </div>
              </SwiperSlide>
            )
          }
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default VideoList;
