import { useAppContext } from "@context/app";
import { segmentEvent } from "@utils/segment";

import { useRouter } from "next/router";
import { ISourceEvent } from "./useEvents";
import useGTM from "./useGTM";

function useAllCategoriesViewed() {
  const { user, country } = useAppContext();
  const { locale } = useRouter();
  const { pushToDataLayer } = useGTM();

  const allCategoriesViewed = (source: ISourceEvent) => {
    segmentEvent("All Categories Viewed", {
      user_id: user.data?.id || -1,
      country_id: country?.id,
      country_slug: country?.slug,
      language: locale?.includes("en") ? "English" : "Arabic",
      ...source,
    });
    pushToDataLayer("All Categories Viewed", {
      user_id: user.data?.id || -1,
      country_id: country?.id,
      country_slug: country?.slug,
      language: locale?.includes("en") ? "English" : "Arabic",
      ...source,
    });
  };

  return { allCategoriesViewed };
}

export default useAllCategoriesViewed;
