import { useAppContext } from "@context/app";
import { segmentEvent } from "@utils/segment";

import { useRouter } from "next/router";
import { ISourceEvent } from "./useEvents";
import useGTM from "./useGTM";

function useCategoryViewed() {
  const { user, country } = useAppContext();
  const { locale } = useRouter();
  const { pushToDataLayer } = useGTM();

  const categoryViewed = (source: ISourceEvent, category: { id?: number; slug?: string }) => {
    segmentEvent("Category Viewed", {
      user_id: user.data?.id || -1,
      country_id: country?.id,
      country_slug: country?.slug,
      language: locale?.includes("en") ? "English" : "Arabic",
      category_id: category.id ?? -1,
      category_slug: category.slug,
      ...source,
    });
    pushToDataLayer("Category Viewed", {
      user_id: user.data?.id || -1,
      country_id: country?.id,
      country_slug: country?.slug,
      language: locale?.includes("en") ? "English" : "Arabic",
      category_id: category.id ?? -1,
      category_slug: category.slug,
      ...source,
    });
  };

  return { categoryViewed };
}

export default useCategoryViewed;
