export const actionGenerator = (action: string) => {
  if (action.includes("openAttributevalue")) {
    return "Attribute";
  } else if (action.includes("openCategories")) {
    return "Categories List";
  } else if (action.includes("openCollection")) {
    return "Collection";
  } else if (action.includes("openCategory")) {
    return "Category";
  } else if (action.includes("openProduct")) {
    return "Product";
  } else if (action.includes("openBrand")) {
    return "Brand";
  } else if (action.includes("openURLinApp")) {
    return "Open URL in App";
  } else if (action.includes("openURL")) {
    return "Open URL";
  } else {
    return "";
  }
};
