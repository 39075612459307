import { IVideoProductInput } from "@types";
import { useEffect, useState } from "react";
import useRequestImmutable from "./useRequestImmutable";
import { productApi } from "gate";
import { video_filter } from "@utils/constants";

function useVideoPlayer() {
  const [videList, setVideoList] = useState<IVideoProductInput[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [curser, setCurser] = useState<string>("");
  const [isFetchingVideo, setIsFetching] = useState(true);

  const { data, error } = useRequestImmutable(productApi.videoList(video_filter));

  useEffect(() => {
    if (data?.data) {
      setVideoList([...data.data.items]);
      setHasMore(data.data.pagination.more_items);
      setCurser(data.data.pagination.cursor);
      setIsFetching(false);
    } else if (error) {
      setIsFetching(false);
    }
  }, [data, error]);

  const fetchData = async () => {
    setIsFetching(true);
    productApi
      .videoList(video_filter, curser)
      .fetch()
      .then((res) => {
        setVideoList((pre) => {
          return [...pre, ...res.data.items];
        });
        setHasMore(res.data.pagination.more_items);
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  return { fetchData, isFetchingVideo, videList, hasMore };
}

export default useVideoPlayer;
