import Link from "next/link";
import { IEntityInput, IHomeItemInput } from "@types";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useRouter } from "next/router";
import { segmentEvent } from "@utils/segment";
import { useAppContext } from "@context/app";
import ImagePrepare from "@elements/imagePrepare";
import useParentSize from "@hooks/useParentSize";
import { useKeenSlider } from "keen-slider/react";
import { linkGenerator } from "@utils/linkGenerator";
import useGTM from "@hooks/useGTM";

interface CategoriesProps {
  data: IHomeItemInput;
  sectionEntity?: IEntityInput;
}

const Categories: React.FC<CategoriesProps> = ({ data, sectionEntity }) => {
  const { locale } = useRouter();
  const { pushToDataLayer } = useGTM();
  const { country, user } = useAppContext()
  const [parentSize, parentRef] = useParentSize();
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    rubberband: false,
    mode: "free-snap",
    renderMode: "performance",
    rtl: locale?.includes("en-") ? false : true,
    slides: { perView: "auto", spacing: 12 },
  }, [])
  return (
    <div className="w-full max-w-[1240px] m-auto categories-container">
      <div className="w-full flex justify-between items-center space-x-2">
        <h1 className="flex-1 font-extrabold sm:text-2xl xs:text-lg text-lg text-[#222222] whitespace-nowrap text-ellipsis overflow-hidden ">
          {data.header?.name}
        </h1>
        {data.header?.more ? (
          <Link
            href={linkGenerator(data.header.more.action)}
            passHref
            prefetch={false}
          >
            <a
              onClick={() => {
                const params = {
                  user_id: user.data?.id || -1,
                  country_id: country?.id,
                  country_slug: country?.slug,
                  language: locale?.includes("en") ? "English" : "Arabic",
                  from_page: "homepage",
                  from_entity: "home",
                  from_entity_slug: sectionEntity?.slug || "",
                  from_page_section: "Categories",
                  from_page_section_entity: "",
                  from_page_section_slug: "",
                }
                segmentEvent("All Categories Viewed", params);
                pushToDataLayer("All Categories Viewed", params);
              }

              }
            >
              <div className={`${locale?.includes("ar") ? "pl-[11px] pr-5" : "pl-5 pr-[11px]"} rounded-full border h-10 flex items-center space-x-1 text-sm font-normal text-brandBlue py-2 `}>
                <span >{data.header.more.name}</span>
                <ChevronRightIcon
                  className={`${locale?.includes("ar") ? "rotate-180" : ""} h-6 w-6`}
                  aria-hidden="true"
                />
              </div>
            </a>
          </Link>
        ) : null}
      </div>
      <div className="w-full mt-5">
        <div ref={sliderRef} className="keen-slider max-w-full ">
          {data.items.map((category) => (
            <div key={`category-${category.id}`} className=" max-w-[192px] min-w-[192px] keen-slider__slide">
              <Link
                href={`/product?category_id=${category.id}`}
                passHref
                prefetch={false}
              >
                <a
                  onClick={() => {
                    const params = {
                      user_id: user.data?.id || -1,
                      country_id: country?.id,
                      country_slug: country?.slug,
                      language: locale?.includes("en") ? "English" : "Arabic",
                      category_id: category.id,
                      category_slug: category.slug,
                      from_page: "homepage",
                      from_entity: "home",
                      from_entity_slug: sectionEntity?.slug || "",
                      from_page_section: "Categories",
                      from_page_section_entity: "",
                      from_page_section_slug: "",
                    }
                    segmentEvent("Category Viewed", params);
                    pushToDataLayer("Category Viewed", params);
                  }}
                >
                  <div className="w-full flex justify-center items-end h-40 mb-5">
                    <div
                      className={` w-48 h-[100px]  rounded-2xl `}
                      style={{ backgroundColor: category.color }}
                    >
                      <div className="w-full h-12 relative">
                        <div className=" absolute bottom-0 left-12">
                          <div className=" w-24 h-24 relative" ref={parentRef} >
                            <ImagePrepare alt={category.name} media={category.icon} width={parentSize.width} />
                          </div>
                        </div>
                      </div>
                      <div className=" w-full h-12 flex items-center justify-center">
                        <p className=" font-normal text-sm text-[#333]">
                          {" "}
                          {category.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
